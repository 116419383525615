import {
  DataSourceMethodsPaginatedOptions,
  DefaultCRUDDataSource,
} from '@tremaze/shared/util-http';
import { ExternalResource } from '@tremaze/external-resource-types';
import { Pagination } from '@tremaze/shared/models';
import { Observable } from 'rxjs';

export abstract class ExternalResourceDataSource extends DefaultCRUDDataSource<ExternalResource> {
  abstract getPaginatedForInstitution(
    instId: string,
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ExternalResource>>;

  abstract createForInstitution(
    instId: string,
    entity: ExternalResource,
  ): Observable<ExternalResource>;

  abstract getOwnPaginated(
    options: DataSourceMethodsPaginatedOptions,
  ): Observable<Pagination<ExternalResource>>;

  abstract hasOwnResources(): Observable<boolean>;
}
